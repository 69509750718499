import React from 'react';
import { RedirectURLs } from '../constants/redirectURLS';
import dropdownPlayStore from '../assets/hq/dropdownPlayStore.png';
import dropdownAppStore from '../assets/hq/dropdownAppStore.png';
import dropdownDapp from '../assets/hq/dropdownDapp.png';
import dropdownChromeExt from '../assets/hq/dropdownChromeExt.png';
import dropdownBusiness from '../assets/hq/dropdownBusiness.png';
import { Dropdown, ConfigProvider } from 'antd';
import { useIsBrowser } from '../hooks/useIsBrowser';

export default function GetStartedDropdow() {
  const isBrowser = useIsBrowser();
  const items = [
    {
      key: '1',
      label: <h2 className='font-semibold text-[20px] px-5 pt-5'>{'Login'}</h2>,
    },
    {
      key: '2',
      label: (
        <div>
          {isBrowser.current &&
            navigator.userAgent.indexOf('Android') !== -1 && (
              <div className='cursor-pointer px-5 pt-5'>
                <a
                  href={RedirectURLs.playStore}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    src={dropdownPlayStore}
                    alt='Play Store'
                    title='Play Store'
                    width='100%'
                    height='auto'
                  />
                </a>
              </div>
            )}
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div>
          {isBrowser.current &&
            navigator.userAgent.indexOf('like Mac') !== -1 && (
              <div className='cursor-pointer px-5 pt-5'>
                <a
                  href={RedirectURLs.appStore}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    src={dropdownAppStore}
                    alt='App Store'
                    title='App Store'
                    width='100%'
                    height='auto'
                  />
                </a>
              </div>
            )}
        </div>
      ),
    },
    {
      key: '4',
      label: (
        <div className='cursor-pointer px-5 pt-5'>
          <a href={RedirectURLs.dApp} target='_blank' rel='noreferrer'>
            <img
              src={dropdownDapp}
              alt='Cypher dApp'
              title='Cypher dApp'
              width='100%'
              height='auto'
            />
          </a>
        </div>
      ),
    },
    {
      key: '5',
      label: (
        <div className='cursor-pointer px-5 pt-5'>
          <a href={RedirectURLs.extension} target='_blank' rel='noreferrer'>
            <img
              src={dropdownChromeExt}
              alt='Chrome Extension'
              title='Chrome Extension'
              width='100%'
              height='auto'
            />
          </a>
        </div>
      ),
    },
    {
      key: '6',
      label: (
        <h2 className='font-semibold text-[14px] px-5 pt-5'>
          {'Business solution'}
        </h2>
      ),
    },
    {
      key: '7',
      label: (
        <div className='cursor-pointer px-5 pt-5'>
          <a href={RedirectURLs.business} target='_blank' rel='noreferrer'>
            <img
              src={dropdownBusiness}
              alt='Cypher Business'
              title='Cypher Business'
              width='100%'
              height='auto'
            />
          </a>
        </div>
      ),
    },
    {
      key: '8',
      label: (
        <h3 className='font-normal text-[12px] px-5 pt-2 pb-5'>
          {'Financial operating system for Web3 Teams '}
        </h3>
      ),
    },
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Dropdown: {
            paddingBlock: 0,
            controlPaddingHorizontal: 0,
            sizePopupArrow: 24,
            colorBgElevated:
              'linear-gradient(90deg, rgba(255, 229, 136, 0.14) 0%, rgba(188, 102, 190, 0.14) 58.93%, rgba(153, 137, 82, 0.14) 100%)',
            borderRadiusLG: 16,
            controlItemBgActiveHover: '',
            controlItemBgHover: '',
            controlItemBgActive: '',
          },
        },
      }}
      dropdown={{
        className: 'backdrop-blur-[15px] rounded-[16px] p-0',
        style: {
          background:
            'linear-gradient(90deg, rgba(255, 229, 136, 0.14) 0%, rgba(188, 102, 190, 0.14) 58.93%, rgba(153, 137, 82, 0.14) 100%)',
        },
      }}
    >
      <Dropdown
        trigger='click'
        menu={{
          items,
        }}
        placement='bottomRight'
        overlayClassName='w-[360px] border border-[#EBEDF0]'
      >
        <button className='focus:outline-none bg-[#F9D26C] rounded-full p-2 md:py-2 md:px-3 flex items-center md:space-x-1 cursor-pointer'>
          <span> Get Started </span>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='25'
            height='24'
            viewBox='0 0 25 24'
            fill='none'
          >
            <path
              d='M16.793 9.293L12.5 13.586L8.20697 9.293L6.79297 10.707L12.5 16.414L18.207 10.707L16.793 9.293Z'
              fill='black'
            />
          </svg>
        </button>
      </Dropdown>
    </ConfigProvider>
  );
}
