import { Dropdown, ConfigProvider } from 'antd';
import React from 'react';
import { navigate } from 'gatsby';

export default function ConsumerDropdown({ walletRef = null }) {
  const items = [
    {
      key: '1',
      label: (
        <p
          className='cursor-pointer font-medium text-[16px] py-[15px] px-[20px] border-b border-[#EBEDF0]'
          onClick={() => {
            navigate('/dapp');
          }}
        >
          {'Cypher Dapp'}
        </p>
      ),
    },
    {
      key: '2',
      label: (
        <p
          className={
            'cursor-pointer font-medium text-[16px] py-[15px] px-[20px]'
          }
          onClick={() => {
            navigate('/');

            setTimeout(() => {
              const downloadSection = document.getElementById(
                'downloadCypherWallet'
              );
              downloadSection.scrollIntoView({ behavior: 'smooth' });
            }, [100]);
          }}
        >
          {'Cypher Wallet'}
        </p>
      ),
    },
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Dropdown: {
            paddingBlock: 0,
            controlPaddingHorizontal: 0,
            sizePopupArrow: 24,
            colorBgElevated:
              'linear-gradient(90deg, rgba(255, 229, 136, 0.30) 0%, rgba(188, 102, 190, 0.30) 58.93%, rgba(153, 137, 82, 0.30) 100%)',
            borderRadiusLG: 16,
          },
        },
      }}
      dropdown={{
        className: 'backdrop-blur-[10px] rounded-[16px] p-0 shadow-none',
        style: {
          background:
            'linear-gradient(90deg, rgba(255, 229, 136, 0.30) 0%, rgba(188, 102, 190, 0.30) 58.93%, rgba(153, 137, 82, 0.30) 100%)',
        },
      }}
    >
      <Dropdown
        trigger='hover'
        menu={{
          items,
        }}
        placement='bottomLeft'
        overlayClassName='w-[360px] border border-[#EBEDF0]'
      >
        <p className='hidden md:block focus:outline-none text-[15px] font-semibold cursor-pointer'>
          Consumer
        </p>
      </Dropdown>
    </ConfigProvider>
  );
}
