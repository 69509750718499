export function redirect(url, openInBlankPage = true) {
  if (typeof window !== 'undefined') {
    openInBlankPage ? window.open(url, '_blank') : window.open(url);
  }
}

export function logAnalytics({ category, action, label }) {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
    });
  }
}
