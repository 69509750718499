import React from 'react';
import NavBar from '../navbar';
import FooterSection from '../footer';
import { ConfigProvider } from 'antd';

const Layout = ({ children }) => {
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: '#FFB900',
            colorTextActive: '#000000',
            // Alias Token
            colorBgContainer: '#f6ffed',
          },
          components: {
            Slider: {
              railSize: 8,
            },
          },
        }}
      >
        <NavBar />
        {children}
        <FooterSection />
      </ConfigProvider>
    </>
  );
};

export default Layout;
