import React, { useEffect, useRef } from 'react';

export const useIsBrowser = () => {
  const isBrowser = useRef(false);

  useEffect(() => {
    isBrowser.current = true;
  }, []);

  return isBrowser;
};
